import {Box, useTheme} from "@mui/material";
import {USE_ADS, TEST_ADS} from "../../config";
import AdsterraHeaderMobile from "./AdsterraHeaderMobile";
import {isMobile} from 'react-device-detect';
import AdsterraHeader from "./AdsterraHeader";

export default function AdsHeader() {
    const theme = useTheme()
    const styles = {
        container_mobile: {
            mb: "5px",
            height: 50,
            minHeight: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.secondary.light
        },
        container: {
            mb: "5px",
            height: 90,
            minHeight: 90,
            width: 700,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.secondary.light
        },
        label: {
            color: theme.palette.text.primary
        }
    }

    const showTestAds = () => {
        if(isMobile) {
            return(
                <Box sx={styles.container_mobile}/>
            )
        }
        else {
            return(
                <Box sx={styles.container}/>
            )
        }
    }

    const ads_view = () => {
        if(TEST_ADS) {
            return (showTestAds())
        }

        if(isMobile)
            return (<AdsterraHeaderMobile/>)
        else
            return (<AdsterraHeader/>)
    }

    const empty_ads = () => {
        return (<></>)
    }

    const set_ui = () => {
        if (USE_ADS === true)
            return(ads_view())
        else
            return (empty_ads())
    }


    return (set_ui())
}