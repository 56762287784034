import {createSlice} from '@reduxjs/toolkit';
import {getPrefDeviceGpu, setPrefDeviceGpu} from "../local-data";

// initial state
const devices = getPrefDeviceGpu();
const initialState = devices
    ? {
        devices: devices.devices,
        algos: devices.algos,
        params: devices.params
    }
    : {devices: {}, algos: {}, params: {vol24h: 0, sortBy: "profit"}};

// ==============================|| SLICE - MENU ||============================== //

const DeviceSlice = createSlice({
    name: 'device_gpu',
    initialState,
    reducers: {
        setCalcGpuDefault(state, action) {
            state.devices = {}
            state.algos = {}
            state.params = {
                vol24h: 0,
                sortBy: "profit"
            }
            setPrefDeviceGpu(state)
        },
        setCalcGpu(state, action) {
            state.devices = action.payload.devices;
            state.algos = action.payload.algos;
            state.params = action.payload.params
            setPrefDeviceGpu(state)
        },
    }
});

const {reducer, actions} = DeviceSlice;
export const {setCalcGpuDefault, setCalcGpu} = actions;
export default reducer;

