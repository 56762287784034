import {createSlice} from '@reduxjs/toolkit';
import {getPrefProfile, setPrefProfile} from "../local-data";

// initial state
const profile = getPrefProfile();
const initialState = profile
    ? {
        locale: profile.locale,
        currency: profile.currency,
        darkMode: profile.darkMode,
        electricPrice: profile.electricPrice,
    }
    : {locale: "en", currency: "usd", darkMode: true, electricPrice: 0.1};

// ==============================|| SLICE - MENU ||============================== //

const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLocale(state, action) {
            state.locale = action.payload.locale;
        },
        setCurrency(state, action) {
            state.currency = action.payload.currency;
        },
        setDarkMode(state, action) {
            state.darkMode = action.payload.darkMode;
            setPrefProfile(state)
        },
        setElectricPrice(state, action) {
            state.electricPrice = action.payload.electricPrice;
            setPrefProfile(state)
        },
        setDefaultElectricPrice(state, action) {
            state.electricPrice = 0.1;
            setPrefProfile(state)
        },
    }
});

const {reducer, actions} = ProfileSlice;
export const {setLocale, setCurrency, setDarkMode, setElectricPrice, setDefaultElectricPrice} = actions;
export default reducer;

