import {Typography, useTheme} from "@mui/material";
import {DATA_UPDATED_EVERY_MINUTES} from "../../config";
import {date_to_dddd_MMMM_DD_YYYY_at_HH_mm} from "../../utils/my-lib";

function TextLastUpdated(props) {
    const theme = useTheme();
    const styles = {
        label: {
            textAlign: props.textAlign,
            p: "2px",
            fontSize: '65%',
            color: theme.palette.text.secondary,
            mb: "5px",
            mr: "3px"
        }
    }
    return (
        <>
            <Typography sx={styles.label}>
                Updated every {DATA_UPDATED_EVERY_MINUTES} minutes.
                Last update : {date_to_dddd_MMMM_DD_YYYY_at_HH_mm(props.value)}
            </Typography>
        </>
    )
}

export default TextLastUpdated