import {
    Box, Card,
    Divider,
    IconButton,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Stack, TextField,
    Toolbar, Typography,
    useTheme
} from "@mui/material";
import {WebLogo} from "../index";
import {getRouterUrl} from "../../router/router-path";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import {useSelector} from "react-redux";
import {useState} from "react";
import {dispatch} from "../../store";
import {setElectricPrice} from "../../store/slices/profile-slice";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import React from "react";
import MiningMonitorLogo from "../../assets/images/mining-monitor2.png"

export default function DrawerWeb(props) {
    const electricPrice = useSelector((state) => state.profile.electricPrice ? state.profile.electricPrice : 0.1)
    const [tempElectricPrice, setTempElectricPrice] = useState(electricPrice)
    const theme = useTheme()
    const styles = {
        title: {
            color: theme.palette.text.secondary,
            mt: "5px",
            ml: "10px",
        },
        menuText: {
            marginLeft: "8px",
            color: theme.palette.text.primary
        },
        iconMenu: {
            color: theme.palette.text.primary
        },
        logoImage: {
            width: 48,
            height: 48,
        },
        logoTitle: {
            color: theme.palette.text.secondary,
            mb: 1
        },
        logoAppName: {
            color: theme.palette.text.primary,
            fontSize: '95%'
        },
    }

    const handleElectricPriceChange = (event) => {
        let inputValue = event.target.value
        const numericValue = Number(inputValue);

        if (!Number.isNaN(numericValue) || inputValue === '') {
            setTempElectricPrice(inputValue);
        }
    }

    const updateElectricalPrice = () => {
        dispatch(setElectricPrice({electricPrice: tempElectricPrice}))
        window.location.reload()
    }
    return (
        <div>
            <Toolbar><WebLogo mode={0}/></Toolbar>
            <Divider/>
            <List>
                <ListItemText primary={"LIST"} sx={styles.title}/>
                <Link underline={'none'} href={getRouterUrl("algo-list")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <Diversity2OutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"ALGOs"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("pool-list")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <HubOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"POOLs"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("coin-list")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <CurrencyBitcoinOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"COINs"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>

            {/* ------------------------------ DEVICE --------------------------------*/}
            <Divider/>
            <List>
                <ListItemText primary={"DEVICES"} sx={styles.title}/>
                <Link underline={'none'} href={getRouterUrl("device-cpu-list")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <MemoryOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"CPUs"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("device-gpu-list")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <MoneyOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"GPUs"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>

            {/* ------------------------------ TOOLS --------------------------------*/}
            <Divider/>
            <List>
                <ListItemText primary={"TOOLS"} sx={styles.title}/>
                <Link underline={'none'} href={getRouterUrl("calc-cpu")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <CalculateOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"CPU Calculator"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("calc-gpu")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <CalculateOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"GPU Calculator"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>
            {/*    <Link underline={'none'} href={"#"}>*/}
            {/*        <ListItem disablePadding>*/}
            {/*            <ListItemButton sx={styles.iconMenu}>*/}
            {/*                <MoneyOutlinedIcon/>*/}
            {/*                <ListItemText sx={styles.menuText} primary={"GPU Rank"}/>*/}
            {/*            </ListItemButton>*/}
            {/*        </ListItem>*/}
            {/*    </Link>*/}
            {/*    <Link underline={'none'} href={"#"}>*/}
            {/*        <ListItem disablePadding>*/}
            {/*            <ListItemButton sx={styles.iconMenu}>*/}
            {/*                <MemoryOutlinedIcon/>*/}
            {/*                <ListItemText sx={styles.menuText} primary={"CPU Rank"}/>*/}
            {/*            </ListItemButton>*/}
            {/*        </ListItem>*/}
            {/*    </Link>*/}
            {/*    <Link underline={'none'} href={"#"}>*/}
            {/*        <ListItem disablePadding>*/}
            {/*            <ListItemButton sx={styles.iconMenu}>*/}
            {/*                <BubbleChartOutlinedIcon/>*/}
            {/*                <ListItemText sx={styles.menuText} primary={"Coin Chart"}/>*/}
            {/*            </ListItemButton>*/}
            {/*        </ListItem>*/}
            {/*    </Link>*/}
            {/*</List>*/}

            <Divider/>
            <List>
                <ListItemText primary={"INFO"} sx={styles.title}/>
                <Link underline={'none'} href={getRouterUrl("request")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <ContactMailOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"Request"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("faq")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <LiveHelpOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"FAQ"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
                <Link underline={'none'} href={getRouterUrl("about-us")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={styles.iconMenu}>
                            <InfoOutlinedIcon/>
                            <ListItemText sx={styles.menuText} primary={"About Us"}/>
                        </ListItemButton>
                    </ListItem>
                </Link>
            </List>

            {
                !props["hideMenuGeneral"] &&
                <>
                    <Divider/>
                    <List>
                        <ListItemText primary={"GENERAL"} sx={styles.title}/>
                        <ListItem disablePadding>
                            <TextField sx={{mt: 1, ml: 2, mr: 2}} size={'small'} label={"Electric Cost"}
                                       value={tempElectricPrice}
                                       inputProps={{
                                           pattern: '[0-9]*',
                                           inputMode: 'numeric',
                                       }}
                                       onChange={handleElectricPriceChange}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$/kWh</InputAdornment>,
                                           endAdornment: <IconButton position="start"
                                                                     onClick={updateElectricalPrice}><Typography>SET</Typography></IconButton>,
                                       }}/>
                        </ListItem>
                    </List>
                </>
            }

            <Card sx={{p: 1, m: 1}}>
                <Typography sx={styles.logoTitle}>Click to view our partner app from the playstore:</Typography>
                <Link underline={'none'}
                      href={"https://play.google.com/store/apps/details?id=fahim_edu.mining_monitor"}
                      to={"https://play.google.com/store/apps/details?id=fahim_edu.mining_monitor"}
                      target={'_blank'}>
                    <Stack direction="row">
                        <Box
                            component="img"
                            sx={styles.logoImage}
                            src={MiningMonitorLogo}/>
                        <Stack sx={{ml: 1}}>
                            <Typography sx={styles.logoAppName}>Best Android</Typography>
                            <Typography sx={styles.logoAppName}>Mining Monitor 2</Typography>
                        </Stack>
                    </Stack>
                </Link>
            </Card>
        </div>
    );
}