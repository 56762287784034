
import {getCountryFlag} from "../../router/router-path";

function CountryFlag(props) {
    const flags = ["au", "br", "cn", "de", "es", "eu", "fr", "ge", "hk", "kr", "ru", "sg", "tw", "us"]
    const { code, fallback = null, ...rest } = props
    if (!code) return fallback
    let flag_code = code.toLowerCase()

    let flag = undefined
    if (flags.includes(flag_code))
        flag =  flag_code
    return flag ? <img {...rest} src={getCountryFlag(flag)}  alt={""}/> : fallback
}
export default CountryFlag