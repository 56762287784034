
export const PREF_PROFILE = "profile"
export function getPrefProfile() {
    return (JSON.parse(localStorage.getItem(PREF_PROFILE)));
}
export function setPrefProfile(data) {
    localStorage.setItem(PREF_PROFILE, JSON.stringify(data));
}

export const PREF_DEVICE_CPU = "device_cpu"
export function getPrefDeviceCpu() {
    return (JSON.parse(localStorage.getItem(PREF_DEVICE_CPU)));
}
export function setPrefDeviceCpu(data) {
    localStorage.setItem(PREF_DEVICE_CPU, JSON.stringify(data));
}

export const PREF_DEVICE_GPU = "device_gpu"
export function getPrefDeviceGpu() {
    return (JSON.parse(localStorage.getItem(PREF_DEVICE_GPU)));
}
export function setPrefDeviceGpu(data) {
    localStorage.setItem(PREF_DEVICE_GPU, JSON.stringify(data));
}

// export const PREF_DEVICE = "device"
// export function getPrefDevice() {
//     return (JSON.parse(localStorage.getItem(PREF_DEVICE)));
// }
// export function setPrefDevice(data) {
//     localStorage.setItem(PREF_DEVICE, JSON.stringify(data));
// }