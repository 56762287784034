import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {getRouterUrl} from "./router/router-path";
import Loadable from "./component/base/Loadable";
import {lazy} from 'react';
import {useSelector} from "react-redux";
import {StatCounter} from "./component";

const RequestPage = Loadable(lazy(() => import('./pages/single/request-page')));
const FaqPage = Loadable(lazy(() => import('./pages/single/faq-page')));
const AboutUs = Loadable(lazy(() => import('./pages/single/about-us')));
const Page404 = Loadable(lazy(() => import('./pages/single/page-404')));
const FrontPage = Loadable(lazy(() => import('./pages/front-page')));
const AlgoList = Loadable(lazy(() => import('./pages/algo-list')));
const PoolList = Loadable(lazy(() => import('./pages/pool-list')));
const CoinList = Loadable(lazy(() => import('./pages/coin-list')));
const PoolPage = Loadable(lazy(() => import('./pages/pool-page')));
const CoinPage = Loadable(lazy(() => import('./pages/coin-page')));
const AlgoPage = Loadable(lazy(() => import('./pages/algo-page')));
const DevicePage = Loadable(lazy(() => import('./pages/device-page')));
const DeviceCpuList = Loadable(lazy(() => import('./pages/device-cpu-list')));
const DeviceGpuList = Loadable(lazy(() => import('./pages/device-gpu-list')));
const CalcCpu = Loadable(lazy(() => import('./pages/calc-cpu')));
const CalcGpu = Loadable(lazy(() => import('./pages/calc-gpu')));

const App = () => {
    const darkMode = useSelector((state) => state.profile.darkMode)

    let theme = createTheme({
        typography: {
            fontSize: 11,
        },
        palette: {
            mode: darkMode ? "dark" : "light"
        }
    })
    theme = responsiveFontSizes(theme);
    theme = createTheme(theme, {
        palette: {
            logo: {
                primary: "#1E90FF",
                secondary: "#6495ED"
            },
            sparkChart: {
                stroke: theme.palette.warning.light,
                fill: theme.palette.divider
            },
            chart: {
                price: theme.palette.success.main,
                priceNeg: theme.palette.error.main,
                reward: theme.palette.warning.main,
                earning: theme.palette.secondary.main,
                hash: theme.palette.primary.main,
                diff: theme.palette.secondary.main,
            },
            uncheck: {
                main: theme.palette.divider,
                contrastText: theme.palette.text.primary,
            },
        },
    });
    theme = responsiveFontSizes(theme);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path={getRouterUrl("request")} element={<RequestPage/>}/>
                    <Route path={getRouterUrl("faq")} element={<FaqPage/>}/>
                    <Route path={getRouterUrl("about-us")} element={<AboutUs/>}/>
                    <Route path={"*"} element={<Page404/>}/>
                    <Route path={"/"} element={<FrontPage/>}/>
                    <Route path={getRouterUrl("calc-cpu")} element={<CalcCpu/>}/>
                    <Route path={getRouterUrl("calc-gpu")} element={<CalcGpu/>}/>
                    <Route path={getRouterUrl("algo-list")} element={<AlgoList/>}/>
                    <Route path={getRouterUrl("pool-list")} element={<PoolList/>}/>
                    <Route path={getRouterUrl("coin-list")} element={<CoinList/>}/>
                    <Route path={getRouterUrl("device-cpu-list")} element={<DeviceCpuList/>}/>
                    <Route path={getRouterUrl("device-gpu-list")} element={<DeviceGpuList/>}/>
                    <Route path={getRouterUrl("coins-by-algo-key")} element={<AlgoPage/>}/>
                    <Route path={getRouterUrl("pools-by-pool-key")} element={<PoolPage/>}/>
                    <Route path={getRouterUrl("pools-by-coin-key")} element={<CoinPage/>}/>
                    <Route path={getRouterUrl("coins-by-device-key")} element={<DevicePage/>}/>
                </Routes>
            </ThemeProvider>
            {/*<StatCounter sc_project={12945600} sc_security="2474cf66" />*/}
        </>
    )
}

export default App;