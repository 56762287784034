import {useTheme} from "@mui/material";

function TextPercentage(props) {
    const theme = useTheme();
    const default_empty = -1000
    const styles = {
        label: {
            display: 'inline',
            textAlign: 'center',
            color: props.value >= 0 ? theme.palette.success.light :
                props.value === default_empty? theme.palette.text.secondary : theme.palette.error.light,
        }
    }

    const get_text = () => {
        if (props.value === default_empty)
            return ("-")
        return (props.value.toFixed(2))
    }
    const get_unit = () => {
        if (props.value === default_empty)
            return ("")
        return ("%")
    }

    const set_view = () => {
        if (props.value === undefined)
            return (<></>)

        return (
            <>
                <span style={styles.label}>
                    {get_text()}{get_unit()}
                </span>
            </>
        )
    }

    return (set_view())
}

export default TextPercentage