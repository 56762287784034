import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {get_hostname_from_url} from "../../utils/my-lib";
import {styles_table_input} from "../../styles/tables";
import {styled} from "@mui/material/styles";

function ViewCoinLink(props) {
    const dict_market = {
        "coingecko": "https://coingecko.com/en/coins/",
        "coinmarketcap": "https://coinmarketcap.com/currencies/",
        "coinpaprika": "https://coinpaprika.com/coin/"
    }
    const dict_social = {
        "reddit": "https://reddit.com/r/",
        "twitter": "https://twitter.com/",
        "telegram": "https://telegram.me/",
        "discord": "https://discord.com/invite/"
    }
    const dict_calc = {
        "whattomine": "https://whattomine.com/coins/"
    }

    const get_href_info = (v, title, key0, key1) => {
        if (!(key0 in v))
            return (<></>);

        let rows = []
        for (let i = 0; i < v[key0][key1].length; i++) {
            let selected = v[key0][key1][i]
            let hostname = selected
            if (selected === "")
                continue

            if (key1 === "explorer" || key1 === "source") {
                hostname = get_hostname_from_url(selected)
            }
            rows.push(<Link display={'block'}  key={selected} href={selected} target="_blank"
                                                    rel="noreferrer">{hostname}</Link>)
        }
        if (rows.length === 0)
            return (<></>);

        return (
            <>
                <StyledTableRow>
                    <StyledTableCell sx={styles_table_input.desc}><Typography>{title}</Typography></StyledTableCell>
                    <StyledTableCell sx={styles_table_input.value}>{rows}</StyledTableCell>
                </StyledTableRow>
            </>
        );
    }

    const get_href_from_dict = (v, title, key0, dict_) => {
        if (!(key0 in v))
            return (<></>);

        let rows = []
        for (let key_ in dict_) {
            if (key_ in v[key0]) {
                let url_ = dict_[key_] + v[key0][key_]
                rows.push(<Link display={'block'} key={key_} href={url_} target="_blank" rel="noreferrer">
                    {get_hostname_from_url(url_)}</Link>)
            }
        }

        if (rows.length === 0) {
            return (<></>);
        }
        return (
            <>
                <StyledTableRow>
                    <StyledTableCell sx={styles_table_input.desc}><Typography>{title}</Typography></StyledTableCell>
                    <StyledTableCell sx={styles_table_input.value}>{rows}</StyledTableCell>
                </StyledTableRow>
            </>
        );
    }

    const get_href_from_object = (v, title, key0, key1) => {
        if (!(key0 in v))
            return (<></>);

        if (!(key1 in v[key0]))
            return (<></>);

        let rows = []
        for (let key_ in v[key0][key1]) {
            let url_ = v[key0][key1][key_]
            if (key_ === "bitcointalk.org") {
                url_ = "https://bitcointalk.org/index.php?topic=" + url_
            } else {

            }
            rows.push(<Link display={'block'} key={key_} href={url_} target="_blank" rel="noreferrer">{key_}</Link>)
        }

        if (rows.length === 0) {
            return (<></>);
        }

        return (
            <>
                <StyledTableRow>
                    <StyledTableCell sx={styles_table_input.desc}><Typography>{title}</Typography></StyledTableCell>
                    <StyledTableCell sx={styles_table_input.value}>{rows}</StyledTableCell>
                </StyledTableRow>
            </>
        );
    }

    const StyledTableRow = styled(TableRow)(() => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "5px 10px 5px 10px"
        }
    }));
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: "80%",
        },
    }));

    return (
        <>
            <Paper sx={{p: 1, mt: 0}}>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {get_href_info(props.coin, "Website", "coinWeb", "url")}
                            {get_href_info(props.coin, "Explorer", "coinWeb", "explorer")}
                            {get_href_info(props.coin, "Source", "coinWeb", "source")}
                            {get_href_from_dict(props.coin, "Market", "coinMarket", dict_market)}
                            {get_href_from_dict(props.coin, "Social Media", "coinMedia", dict_social)}
                            {get_href_from_object(props.coin, "Forum", "coinMedia", "forum")}
                            {get_href_from_dict(props.coin, "Calculator", "coinCalc", dict_calc)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

export default ViewCoinLink