import {Typography, useTheme} from "@mui/material";

function ViewCoinUnit(props) {
    const theme = useTheme();
    const styles = {
        label: {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '90%',
            backgroundColor: theme.palette.info.dark,
            color: theme.palette.background.default,
            padding: '2px 1px 2px 1px'
        }
    }
    return (
        <>
            <Typography sx={styles.label}>{props.value}</Typography>
        </>
    )
}

export default ViewCoinUnit