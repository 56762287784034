import {Box, Grid, Link, Typography, useTheme} from "@mui/material";
import {getCoinLogo, getRouterUrl} from "../../router/router-path";

function CoinLogoAlgoView(props) {
    //  --------
    //  |      |  Coin Name (Coin Symbol)
    //  | LOGO |
    //  |      |  Algo name
    //  --------
    const theme = useTheme();
    const styles = {
        symbol: {
            fontWeight: 'normal',
            color: theme.palette.info.main,
        },
        name: {
            fontSize: '100%',
            color: theme.palette.text.secondary,
        }
    }

    const get_symbol = () => {
        if (props["showSymbol"])
            return(`(${props.symbol})`)
        return (<></>)
    }
    return (
        <Link underline={'none'} href={getRouterUrl("pools-by-coin-key", "/", {coinKey: props.coinKey})}>
            <Grid container direction="row" alignItems={'center'} style={{cursor: 'pointer'}}>
                <Grid item>
                    <Box
                        component="img"
                        sx={{height: props.logoSize, width: props.logoSize, mr: 1}}
                        src={getCoinLogo(props.coinKey)}
                    />
                </Grid>
                <Grid>
                    <Typography sx={styles.symbol}>{props.name} {get_symbol()}</Typography>
                    {props["showAlgo"] &&
                        <Typography sx={styles.name}>{props.algo}</Typography>
                    }
                </Grid>
            </Grid>
        </Link>
    )
}

export default CoinLogoAlgoView