import {createSlice} from '@reduxjs/toolkit';
import {getPrefDeviceCpu, setPrefDeviceCpu} from "../local-data";

// initial state
const devices = getPrefDeviceCpu();
const initialState = devices
    ? {
        devices: devices.devices,
        algos: devices.algos,
        params: devices.params
    }
    : {devices: {}, algos: {}, params: {vol24h: 0, sortBy: "profit"}};

// ==============================|| SLICE - MENU ||============================== //

const DeviceSlice = createSlice({
    name: 'device_cpu',
    initialState,
    reducers: {
        setCalcCpuDefault(state, action) {
            state.devices = {}
            state.algos = {}
            state.params = {
                vol24h: 0,
                sortBy: "profit"
            }
            setPrefDeviceCpu(state)
        },
        setCalcCpu(state, action) {
            state.devices = action.payload.devices;
            state.algos = action.payload.algos;
            state.params = action.payload.params
            setPrefDeviceCpu(state)
        },
    }
});

const {reducer, actions} = DeviceSlice;
export const {setCalcCpuDefault, setCalcCpu} = actions;
export default reducer;

