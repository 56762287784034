import {check_and_parse_negative, ms_to_date_formatted, ms_to_date_time} from "../../utils/my-lib";
import {Tooltip, Typography, useTheme} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function ViewLastBlock(props) {
    const theme = useTheme();
    const styles = {
        block: {
            textAlign: "center",
            fontSize: '95%'
        },
        date: {
            textAlign: 'center',
            color: theme.palette.background.default,
            fontSize: '95%'
        },
        tooltip: {
            fontSize: '13px',
            ml: '2px',
            color: theme.palette.error.light
        }
    }

    const val = ms_to_date_formatted(props.blockTime, "")
    const create_tooltip_text = (v) => {
        return (
            <>
                <Typography sx={{fontSize: '120%'}}>
                    Last updated at {ms_to_date_time(v, "")}
                </Typography>
            </>
        )
    }
    const create_tooltip_view = () => {
        if (props.tCheck === props.tUpdate)
            return (<></>)

        return (
            <>
                <Tooltip title={props.tUpdate > 0 && create_tooltip_text(props.tUpdate)}><InfoOutlinedIcon
                    sx={styles.tooltip}/></Tooltip>
            </>
        )
    }
    return (
        <>
            <Typography sx={styles.block}>
                {check_and_parse_negative(props.blockHeight, "")}{create_tooltip_view()}</Typography>
            <Typography sx={styles.date} bgcolor={val.c}>
                {val.v}</Typography>
        </>
    )
}

export default ViewLastBlock