import {Autocomplete, Box, TextField, Typography, useTheme} from "@mui/material";
import {getCoinLogo, getRouterUrl} from "../../router/router-path";
import {useNavigate} from "react-router-dom";
import {styles_table} from "../../styles/tables";

function DropdownCoin(props) {
    const theme = useTheme();
    let navigate = useNavigate();
    return (
        <>
            <Box>
                <Autocomplete size={'small'}
                              value={props.selected}
                              blurOnSelect={true}
                              clearOnBlur={true}
                              sx={{width: 200}}
                              options={props.coins}
                              disableClearable
                              autoHighlight
                              getOptionLabel={(option) => option.n + " " + option.k}
                              isOptionEqualToValue={(option, value) => option.k === value.k}
                              renderOption={(props, option) => (
                                  <Box component="li" sx={{'& > img': {mr: 1}, p: 0}} {...props}>
                                      <img
                                          loading="lazy"
                                          height="16"
                                          src={getCoinLogo(option.k)}
                                          alt=""
                                      />
                                      <div>
                                          <Typography>{option.n} - <span
                                              style={{color: theme.palette.info.main}}>{option.s}</span>
                                          </Typography>
                                          {
                                              option.m === 1 && (
                                                  <Typography sx={styles_table.subheader_normal}>{option.a}</Typography>
                                              )
                                          }
                                      </div>
                                  </Box>
                              )}
                              renderInput={(params) => (
                                  <TextField size={'small'}
                                             {...params}
                                             onChange={null}
                                             label="Search a coin"
                                  />
                              )}
                              onChange={(event, newValue) => {
                                  navigate(getRouterUrl("pools-by-coin-key", "/", {coinKey: newValue['k']}))
                              }}
                />
            </Box>
        </>
    )
}

export default DropdownCoin