import {Box, Grid, Link, Typography, useTheme} from "@mui/material";
import {styles_table} from "../../styles/tables";
import MaterialReactTable from "material-react-table";
import {useRef} from "react";
import {NumericFormat} from "react-number-format";
import CircleIcon from '@mui/icons-material/Circle';
import {MAX_MARKET_PER_VIEW} from "../../config";
import {getTradeLogo} from "../../router/router-path";

function ViewCoingecko(props) {
    const theme = useTheme();
    const tableContainerRef = useRef(null);

    const styles = {
        label: {
            textAlign: 'right',
            p: "2px",
            fontSize: '65%',
            color: theme.palette.text.secondary,
            mb: "3px",
            mr: "3px"
        },
        pair: {
            fontSize: '110%'
        },
        pair_usd: {
            color: theme.palette.text.secondary,
            fontSize: '100%'
        },
        volume: {
            fontSize: '110%'
        }
    }

    const  getCoinSymbol = (v) => {
        if('symbol' in v) {
            return(v['symbol'].toUpperCase());
        }
        else {
            return ('');
        }
    }

    const column_market = [
        {
            accessorKey: 'market.name',
            header: "Exchanges",
            Header: () => (<><Typography sx={{pl: 1, fontWeight: 'bold'}}>
                 {getCoinSymbol(props.data)} Exchanges
            </Typography></>),
            enableColumnActions: false,
            enableSorting: true,
            size: 180,
            muiTableHeadCellProps: {
                align: 'left',
            },
            Cell: ({cell, row}) => (
                <>
                    <Grid container sx={{pl: 1}}>
                        <Grid item>
                            <Box
                                component="img"
                                sx={{height: 20, width: 20, pr: "3px"}}
                                src={getTradeLogo(row.original["trade_url"])}
                            />
                        </Grid>
                        <Grid item>
                            <Link
                                variant="body2"
                                href={row.original['trade_url']} target={"_blank"}>

                                <Typography
                                    sx={{fontWeight: 'normal'}}>{cell.getValue()}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </>
            ),
        },
        {
            accessorKey: 'converted_last.usd',
            header: 'Pair',
            enableColumnActions: false,
            enableSorting: true,
            size: 75,
            muiTableHeadCellProps: {
                align: 'left',
            },
            Cell: ({cell, row}) => (
                <>
                    <Typography sx={styles.pair}>{row.original['base']}/{row.original['target']}</Typography>
                    <Typography sx={styles.pair_usd}>
                        {row.original["converted_volume"]["usd"] > 0 &&
                            <NumericFormat value={cell.getValue()} displayType="text" thousandSeparator prefix="$"
                                           decimalScale={6}/>
                        }
                    </Typography>
                </>
            ),
        },
        {
            accessorKey: 'converted_volume.usd',
            header: 'Vol 24h',
            enableColumnActions: false,
            enableSorting: true,
            size: 80,
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({cell, row}) => (
                <>
                    <Grid container direction="row" alignItems="flex-end" justifyContent="space-between" sx={{pr: 1}}>
                        <Grid item>
                            {row.original['trust_score'] !== null &&
                                <CircleIcon sx={{fontSize: '90%', color: row.original['trust_score']}}/>
                            }
                        </Grid>
                        <Grid item>
                            <Box align={'right'} sx={styles.volume}>
                                {cell.getValue() <= 0 ? <Typography sx={{color: theme.palette.error.main}}>-</Typography> :
                                    <NumericFormat value={cell.getValue()} displayType="text" thousandSeparator prefix="$"
                                                   decimalScale={0}/>}
                            </Box>
                        </Grid>
                    </Grid>
                </>
            ),
        }
    ]
    return (
        <>
            <Box sx={{pt: 0}}>
                <MaterialReactTable
                    columns={column_market}
                    data={props.data.tickers}
                    enableStickyHeader
                    manualSorting={false}
                    enableTopToolbar={false}
                    enableStickyFooter={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnFilters={false}
                    enableBottomToolbar={props.data.tickers.length > MAX_MARKET_PER_VIEW}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: styles_table.head_cell_prop,
                    }}
                    muiTableBodyCellProps={{
                        sx: styles_table.body_cell_market_prop,
                    }}
                    muiTableContainerProps={{
                        ref: tableContainerRef, //get access to the table container element
                        sx: styles_table.table_container_prop,
                    }}
                    initialState={{
                        pagination: {pageSize: MAX_MARKET_PER_VIEW, pageIndex: 0}, density: 'compact',
                        sorting: [
                            {
                                id: 'converted_volume.usd',
                                desc: true
                            }
                        ]
                    }}
                />
                <Typography sx={styles.label}>
                    Powered by <Link href={'https://www.coingecko.com/'} target="_blank" rel="noreferrer">Coingecko</Link></Typography>
            </Box>
        </>
    )
}

export default ViewCoingecko