import {
    chartSparkHeight,
    chartSparkWidth,
    SPARK_TIME_SPACING
} from "../../config";
import {useTheme} from "@mui/material";
import {date_to_ddd_MMM_D_hh_mm} from "../../utils/my-lib";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend
);

function SparklineHashrate(props) {
    const theme = useTheme();
    const styles = {
        container: {
            width: chartSparkWidth,
            height: chartSparkHeight
        }
    }
    let options = {
        type: 'line',
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                fontSize: "80%",
                backgroundColor: 'rgba(0, 0, 0, 0)',
                titleFont: {
                    size: 9
                },
                bodyFont: {
                    size: 9
                },
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        let label = `${context.parsed.y} ${props.data["u"]}${props.unit}`
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                type: 'linear',
                display: false,
            },
        }
    }

    const createChartData = (xy) => {
        if (xy === undefined)
            return ([])
        let last_time = xy["lt"];
        let nn = xy["v"].length;
        let dtime = SPARK_TIME_SPACING
        let rr = {datetime: [], hr: []}
        for (let i = 0; i < nn; i++) {
            rr.datetime.push(date_to_ddd_MMM_D_hh_mm(last_time - ((nn - i - 1) * dtime)))
            rr.hr.push(xy["v"][i] < 0 ? 0 : xy["v"][i])
        }
        return (rr)
    }

    let datas = createChartData(props.data)
    const data = {
        labels: datas["datetime"],
        datasets: [
            {
                label: 'Hashrate',
                data: datas["hr"],
                borderColor: theme.palette["sparkChart"]["stroke"],
                backgroundColor: theme.palette["sparkChart"]["fill"],
                borderWidth: 1,
                pointRadius: 0,
                fill: true,
            },
        ],
    };
    return (
        <div style={styles.container}>
            <Line options={options} data={data}/>
        </div>
    );
}

export default SparklineHashrate