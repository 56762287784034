import Tooltip from '@mui/material/Tooltip';
import {Box} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import CountryFlag from "../view/country-flag";

function ViewWorldLoc(props) {
    return (
        <>
            <Tooltip title={props.value}>
                <Box>
                    <span style={{fontWeight: 'bold'}}>{props.prefix}</span>
                    {
                        props.value.split(",").map(item =>
                            <span key={item} style={{marginRight: "2px"}}>
                                    {item.length > 2 && (
                                        <PublicIcon sx={{color: '#1E90FF', fontSize: 15}}/>
                                    )
                                    }
                                <CountryFlag width={18} height={12} code={item}/>
                                </span>
                        )
                    }
                </Box>
            </Tooltip>
        </>
    )
}

export default ViewWorldLoc