import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Typography, useTheme
} from "@mui/material";
import * as MyLib from "../../utils/my-lib";
import {styles_table_input} from "../../styles/tables";
import {NumericFormat} from 'react-number-format';
import {styled} from "@mui/material/styles";
import TextPercentage from "../item/text-percentage";
import {base_to_unit_symbol, get_better_decimal_format} from "../../utils/my-lib";
import {getRouterUrl} from "../../router/router-path";

function ViewCoinStats(props) {
    const theme = useTheme();

    const styles = {
        note: {
            fontSize: "70%",
            fontStyle: 'normal',
            color: theme.palette.text.secondary
        }
    }
    const StyledTableRow = styled(TableRow)(() => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "5px 10px 5px 10px"
        }
    }));
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: "80%",
        },
    }));

    const get_pos_neg_color = (ori, current) => {
        if (ori <= current)
            return (theme.palette.success.light)
        else
            return (theme.palette.error.light)
    }

    const get_price = () => {
        return (props.coin["price"])
    }
    const get_reward = () => {
        return (props.coin["dailyReward"])
    }
    const get_revenue = () => {
        return (props.coin["price"] * props.coin["dailyReward"])
    }

    let price = get_price()
    let reward = get_reward()
    let revenue = get_revenue()
    const create_view = () => {
        if (!("algoName" in props.coin))
            return (<></>)

        return (
            <>
                <Paper sx={{p: 1, mt: 0}}>
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell
                                        sx={styles_table_input.desc}><Typography>Algorithm</Typography></StyledTableCell>
                                    <StyledTableCell
                                        sx={styles_table_input.value}>
                                        <Link underline={'none'}
                                              href={getRouterUrl("coins-by-algo-key", "/", {algoKey: props.coin["algoKey"]})}>
                                            <Typography>{props.coin["algoName"]}</Typography>
                                        </Link>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Block Time/Avg
                                        (sec)</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography display={'inline'}>{props.coin["blockTime"]}</Typography>
                                        {
                                            props.coin["avgBlockTime"] > 0 &&
                                            <>
                                                <Typography display={'inline'}> / </Typography>
                                                <Typography display={'inline'}
                                                            color={get_pos_neg_color(props.coin["blockTime"], props.coin["avgBlockTime"])}>
                                                    {props.coin["avgBlockTime"]}</Typography>
                                            </>
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Block
                                        Reward</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <NumericFormat
                                            value={props.coin["curBlockReward"] > 0 ? props.coin["curBlockReward"].toFixed(4)
                                                : props.coin["blockReward"].toFixed(4)}
                                            displayType="text" thousandSeparator suffix={" " + props.coin["coinSymbol"]}
                                            decimalScale={get_better_decimal_format(props.coin["curBlockReward"] > 0 ? props.coin["curBlockReward"].toFixed(4)
                                              : props.coin["blockReward"].toFixed(4))}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Daily
                                        Emission</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <NumericFormat
                                            value={props.coin["dailyEmission"].toFixed(2)}
                                            displayType="text" thousandSeparator suffix={" " + props.coin["coinSymbol"]}
                                            decimalScale={2}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}/>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        {
                                            props.coin["price"] < 0 && <>N/A</>
                                        }
                                        <NumericFormat
                                            value={props.coin["price"] < 0 ? "" : (props.coin["dailyEmission"] * props.coin["price"]).toFixed(2)}
                                            displayType="text" thousandSeparator prefix="$"
                                            decimalScale={2}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Known
                                        Hashrate</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography display={'inline'}
                                                    sx={{pr: 1}}>{MyLib.to_hashrate(props.coin["totPoolHash"], props.coin["algoUnit"])}</Typography>
                                        <Typography display={'inline'}>{(props.coin["hashrate"] <= 0) ? "" : "(" + (props.coin["totPoolHash"] / props.coin["hashrate"] * 100.0).toFixed(2) + "%)"}</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Network
                                        Hashrate*</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography
                                            display={'inline'}>{MyLib.to_hashrate(props.coin["hashrate"], props.coin["algoUnit"])}</Typography>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist1d"]["pHr"]}/>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist7d"]["pHr"]}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={styles_table_input.desc}><Typography>Network
                                        Difficulty*</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography
                                            display={'inline'}>{MyLib.to_hashrate(props.coin["difficulty"], props.coin["algoUnit"], 2, "N/A", false)}</Typography>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist1d"]["pDf"]}/>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist7d"]["pDf"]}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell
                                        sx={styles_table_input.desc}><Typography>Price*</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography display={'inline'}>
                                            {
                                                price < 0 && <>N/A</>
                                            }
                                            <NumericFormat value={price<0?"":price} displayType="text" thousandSeparator
                                                           prefix={"$"}
                                                           decimalScale={get_better_decimal_format(price)}/>
                                        </Typography>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist1d"]["pPr"]}/>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist7d"]["pPr"]}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell
                                        sx={styles_table_input.desc}><Typography>1 {base_to_unit_symbol(props.coin["baseUnit"],
                                        props.coin["algoUnit"])} Rewards*</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography display={'inline'}>
                                            <NumericFormat value={reward} displayType="text" thousandSeparator
                                                           decimalScale={get_better_decimal_format(reward)}
                                                           suffix={" " + props.coin["coinSymbol"]}/>
                                        </Typography>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist1d"]["pRw"]}/>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist7d"]["pRw"]}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell
                                        sx={styles_table_input.desc}><Typography>1 {base_to_unit_symbol(props.coin["baseUnit"],
                                        props.coin["algoUnit"])} Revenue*</Typography></StyledTableCell>
                                    <StyledTableCell sx={styles_table_input.value}>
                                        <Typography display={'inline'}>
                                            {
                                                price < 0 && <>N/A</>
                                            }

                                            <NumericFormat value={price<0?"":revenue} displayType="text" thousandSeparator
                                                           prefix={"$"}
                                                           decimalScale={get_better_decimal_format(revenue)}/>
                                        </Typography>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist1d"]["pEr"]}/>
                                        <Typography display={'inline'}> / </Typography>
                                        <TextPercentage display={'inline'} value={props.coin["hist7d"]["pEr"]}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography sx={styles.note}>Note: *<i>current</i> / <i>avg 1D</i> / <i>avg 1W</i></Typography>
                </Paper>
            </>
        )
    }

    return (create_view())
}

export default ViewCoinStats