import {check_and_parse_negative} from "../../utils/my-lib";
import {Typography} from "@mui/material";

function ViewTextField(props) {
    const value = check_and_parse_negative(props.value, props.empty);
    return (
        <>
            <Typography sx={{textAlign: props.textAlign}}>{value}</Typography>
        </>
    )
}

export default ViewTextField