import {ASSETS_URL, DEBUG_MODE, SERVER_URL} from "../config";

export function getRouterUrl(key, prefix="/", param={}) {
    let routers = {
        "404": prefix + "error-404",
        "request": prefix + "request",
        "faq": prefix + "faq",
        "home": prefix + "/",
        "about-us": prefix + "about-us",
        "algo-list": prefix + "algo-list",
        "pool-list": prefix + "pool-list",
        "coin-list": prefix + "coin-list",
        "device-asic-list": prefix + "asic-list",
        "device-cpu-list": prefix + "cpu-list",
        "device-gpu-list": prefix + "gpu-list",
        "calc-asic": prefix + "asic-calculator",
        "calc-cpu": prefix + "cpu-calculator",
        "calc-gpu": prefix + "gpu-calculator",
        "coins-by-algo-key": prefix + "algo" + (("algoKey" in param) ? `/${param["algoKey"]}` : "/:algoKey"),
        "pools-by-pool-key": prefix + "pool" + (("poolKey" in param) ? `/${param["poolKey"]}` : "/:poolKey"),
        "pools-by-coin-key": prefix + "coin" + (("coinKey" in param) ? `/${param["coinKey"]}` : "/:coinKey"),
        "coins-by-device-key": prefix + "device" + (("deviceKey" in param) ? `/${param["deviceKey"]}` : "/:deviceKey"),
    }
    return(routers[key])
}

export function getRouterApi(key, param={}) {
    let apis = {
        "best-coin": SERVER_URL + "/locked/all/best_coin.json",
        "algo-list": SERVER_URL + "/locked/all/algo_list.json",
        "pool-list": SERVER_URL + "/locked/all/pool_list.json",
        "coin-list": SERVER_URL + "/locked/all/coin_list.json",
        "device-asic-list": SERVER_URL + "/locked/all/asic_list.json",
        "device-gpu-list": SERVER_URL + "/locked/all/gpu_list.json",
        "device-cpu-list": SERVER_URL + "/locked/all/cpu_list.json",
        "calc-asic": SERVER_URL + "/locked/calc/calc_asic.json",
        "calc-asic-data": SERVER_URL + "/locked/calc/calc_asic_data.json",
        "calc-cpu": SERVER_URL + "/locked/calc/calc_cpu.json",
        "calc-cpu-data": SERVER_URL + "/locked/calc/calc_cpu_data.json",
        "calc-gpu": SERVER_URL + "/locked/calc/calc_gpu.json",
        "calc-gpu-data": SERVER_URL + "/locked/calc/calc_gpu_data.json",
        "get-coin-dropdown-list": SERVER_URL + `/locked/all/coin_dropdown_list.json`,
        "get-oc-by-algo": SERVER_URL + `/locked/algo/${param["algoKey"]}.json`,
        "coins-by-algo-key": SERVER_URL + `/locked/algos/${param["algoKey"]}.json`,
        "pools-by-pool-key": SERVER_URL + `/locked/pools/${param["poolKey"]}.json`,
        "pools-by-coin-key": SERVER_URL + `/locked/pool/${param["coinKey"]}.json`,
        "coins-by-device-key": SERVER_URL + `/locked/device/${param["deviceKey"]}.json`,
        "get-coin-info": SERVER_URL + `/locked/coin/${param["coinKey"]}.json`,
        "get-coin-network": SERVER_URL + `/locked/network/${param["coinKey"]}.json`,
        "get-coin-market": SERVER_URL + `/locked/market/${param["coinKey"]}.json`,
        "get-coin-gecko": `https://api.coingecko.com/api/v3/coins/${param["coingecko"]}?localization=en&tickers=true&market_data=false&community_data=false&developer_data=false&sparkline=false`,
    }
    let url = apis[key]
    if (DEBUG_MODE)
        console.log(url)
    return(url)
}

export function getRouterTitle(key, param={}) {
    let apis = {
        "front-page": "Best Mining Reference",
        "algo-list": "ALGOs - MiningRank",
        "pool-list": "POOLs - MiningRank",
        "coin-list": "COINs - MiningRank",
        "device-gpu-list": "GPUs - MiningRank",
        "device-cpu-list": "CPUs - MiningRank",
        "device-asic-list": "ASICs - MiningRank",
        "pool-details": "Pool Details",
        "calc-asic": "ASICs Calculator",
        "calc-cpu": "CPUs Calculator",
        "calc-gpu": "GPUs Calculator",
    }
    let url = apis[key]
    return(url)
}

export function getDeviceLogo(key) {
    return(ASSETS_URL + `/assets/device/32/${key}.png`)
}
export function getPoolLogo(key) {
    return(ASSETS_URL + `/assets/pool/64/${key}.png`)
}
export function getCoinLogo(key) {
    return(ASSETS_URL + `/assets/coin/64/${key}.png`)
}
export function getCountryFlag(key) {
    return(ASSETS_URL + `/assets/flags/4x3/${key}.svg`)
}
export function getMarketLogo(key) {
    return(ASSETS_URL + `/assets/market/64/${key}.png`)
}

export function getTradeLogo(url_) {
    let hostname = ""
    try {
        hostname = new URL(url_).hostname
    }
    catch (e) {

    }
    hostname = hostname.replace("www.", "").replaceAll(".", "_")
    return(ASSETS_URL + `/assets/market/32/${hostname}.png`)
}