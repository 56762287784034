import {
    Link, Paper, Typography, useTheme,
} from "@mui/material";
import React from 'react';

export default function WebFooter() {
    const theme = useTheme();
    const styles = {
        box: {
            mt: 1,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            p: 2,
        },
        text: {
            fontSize: '75%'
        }
    }
    return (
        <>
            <Paper sx={styles.box}>
                <Typography sx={styles.text}><b>Copyright&copy;2023 </b><Link
                    href={"https://miningrank.com"}><b>MiningRank.com</b></Link>.
                    All rights reserved</Typography>
                <Typography sx={styles.text}>Disclaimer: MiningRank.com are not responsible for any pool, coin exchange, and
                    Overclocking listed here. Overclocking involves risks of hardware damage and voiding warranties.
                    Proceed with caution and take personal responsibility for any consequences that may
                    arise</Typography>
            </Paper>
        </>
    );
}