export function to_formatted_money(v, prefix="$", precision=2, default_neg='N/A') {
    v = v*1;
    if(v < 0)
        return(default_neg)

    if(v < 0)
        return(default_neg)
    else if (v < 0.00001)
        return (`${prefix}${v.toFixed(precision+5)}`)
    else if (v < 0.0001)
        return (`${prefix}${v.toFixed(precision+4)}`)
    else if (v < 0.001)
        return (`${prefix}${v.toFixed(precision+3)}`)
    else if (v < 0.01)
        return (`${prefix}${v.toFixed(precision+2)}`)
    else if (v < 0.1)
        return (`${prefix}${v.toFixed(precision+1)}`)
    else if (v < 1000)
        return (`${prefix}${v.toFixed(precision)}`)
    else if (v < 1e+6)
        return (`${prefix}${(v/1e+3).toFixed(precision)} K`)
    else if (v < 1e+9)
        return (`${prefix}${(v/1e+6).toFixed(precision)} M`)
    else if (v < 1e+12)
        return (`${prefix}${(v/1e+9).toFixed(precision)} B`)
    else
        return (`${prefix}${v}`)
}

export function to_hashrate(v, unit='h/s', precision=2, default_neg='N/A', full_unit=true) {
    if(v===undefined)
        return ""

    unit = get_first_unit(unit, full_unit)

    v = v*1;
    if(v < 0)
        return(default_neg)
    else if (v < 0.00001)
        return (`${v.toFixed(precision+5)} ${unit}`)
    else if (v < 0.0001)
        return (`${v.toFixed(precision+4)} ${unit}`)
    else if (v < 0.001)
        return (`${v.toFixed(precision+3)} ${unit}`)
    else if (v < 0.01)
        return (`${v.toFixed(precision+2)} ${unit}`)
    else if (v < 0.1)
        return (`${v.toFixed(precision+1)} ${unit}`)
    else if (v < 1000)
        return (`${v.toFixed(precision)} ${unit}`)
    else if (v < 1e+6)
        return (`${(v/1e+3).toFixed(precision)} K${unit}`)
    else if (v < 1e+9)
        return (`${(v/1e+6).toFixed(precision)} M${unit}`)
    else if (v < 1e+12)
        return (`${(v/1e+9).toFixed(precision)} G${unit}`)
    else if (v < 1e+15)
        return (`${(v/1e+12).toFixed(precision)} T${unit}`)
    else if (v < 1e+18)
        return (`${(v/1e+15).toFixed(precision)} P${unit}`)
    else if (v < 1e+21)
        return (`${(v/1e+18).toFixed(precision)} E${unit}`)
    else
        return (`${(v/1e+21).toFixed(precision)} Z${unit}`)
}

export function base_to_unit_symbol(v, unit='', full_unit=true) {
    unit = get_first_unit(unit, full_unit)

    if (v === 3)
        return("K" + unit)
    else if(v === 6)
        return("M" + unit)
    else if(v === 9)
        return("G" + unit)
    else if(v === 12)
        return("T" + unit)
    else if(v === 15)
        return("P" + unit)
    else if(v === 18)
        return("E" + unit)
    else
        return (unit)
}

export function get_first_unit(unit, full_unit=true) {
    if(full_unit === false) {
        if (unit !== "") {
            let split_str = unit.split("/")
            if (split_str.length > 1)
                unit = split_str[0].trim()
        }
    }

    return(unit)
}

export function get_better_decimal_format(v) {
    v = Math.abs(v)
    if (v <= 0.0000001)
        return(10)
    else if (v <= 0.000001)
        return(9)
    else if (v <= 0.00001)
        return(8)
    else if (v <= 0.0001)
        return(7)
    else if (v <= 0.001)
        return(6)
    else if (v <= 0.01)
        return(5)
    else if (v <= 0.1)
        return(4)
    else if (v <= 1)
        return(3)
    else if (v <= 100)
        return(2)
    else
        return(0)
}

export function get_better_decimal_chart_label_format(v) {
    v = Math.abs(v)
    if (v <= 0.0000001)
        return(9)
    else if (v <= 0.000001)
        return(8)
    else if (v <= 0.00001)
        return(7)
    else if (v <= 0.0001)
        return(6)
    else if (v <= 0.001)
        return(5)
    else if (v <= 0.01)
        return(4)
    else if (v <= 0.1)
        return(3)
    else if (v < 1)
        return(2)
    else if (v < 10)
        return(1)
    else
        return(0)
}

export function number_to_text(v, def_neg="N/A", decAdd=0) {
    if (v<0)
        return def_neg

    let dec_number = 0
    if (v <= 0.0000001)
        dec_number = 9 + decAdd
    else if (v <= 0.000001)
        dec_number = 8 + decAdd
    else if (v <= 0.00001)
        dec_number = 7 + decAdd
    else if (v <= 0.0001)
        dec_number = 6 + decAdd
    else if (v <= 0.001)
        dec_number = 5 + decAdd
    else if (v <= 0.01)
        dec_number = 4 + decAdd
    else if (v <= 0.1)
        dec_number = 3 + decAdd
    else
        dec_number = 2 + decAdd
    return v.toFixed(dec_number)
}

export function ms_to_date_formatted(time_in_sec, default_neg="N/A") {
    if(time_in_sec<=0) {
        return ({v: default_neg, c:"white"});
    }

    let now = new Date();
    let now_ms = now.getTime()/1000;

    let colors = [
        '#32CD32',
        '#008000',
        '#006400',
        '#6B8E23',
        '#DAA520',
        '#D2691E',
        '#A9A9A9',
        '#808080',
    ]
    let time_90_sec = 90;
    let time_5_min = 5*60;
    let time_15_min = 15*60;
    let time_2_hr = 2*60*60;
    let time_6_hr = 6*60*60;
    let time_12_hr = 12*60*60;
    let time_48_hr = 48*60*60;
    let time_5_day = 5*24*60*60;
    let time_1_month = 30*24*60*60;
    let time_1_year = 12*30*24*60*60;

    let dt = now_ms - time_in_sec
    if(dt < time_90_sec) {
        let v = dt;
        return ({v: `${v|0} sec`, c: colors[0]})
    }
    else if(dt < time_5_min) {
        let v = dt / 60;
        return ({v: `${v|0} min`, c: colors[0]})
    }
    else if(dt < time_15_min) {
        let v = dt / 60;
        return ({v: `${v|0} min`, c: colors[0]})
    }
    else if(dt < time_2_hr) {
        let v = dt / 60;
        return ({v: `${v|0} min`, c: colors[2]})
    }
    else if(dt < time_6_hr) {
        let v = dt / 60 / 60;
        return ({v: `${v|0} hr`, c: colors[3]})
    }
    else if(dt < time_12_hr) {
        let v = dt / 60 / 60;
        return ({v: `${v|0} hr`, c: colors[4]})
    }
    else if(dt < time_48_hr) {
        let v = dt / 60 / 60;
        return ({v: `${v|0} hr`, c: colors[5]})
    }
    else if(dt < time_5_day) {
        let v = dt / 60 / 60 / 24;
        return ({v: `${v|0} day`, c: colors[6]})
    }
    else if (dt < time_1_month) {
        let v = dt / 60 / 60 / 24;
        return ({v: `${v|0} day`, c: colors[7]})
    }
    else if (dt > time_1_month) {
        let v = dt / 30 / 60 / 60 / 24;
        return ({v: `${v|0} mo`, c: colors[7]})
    }
    else if (dt < time_1_year) {
        let v = dt / 30 / 60 / 60 / 24;
        return ({v: `${v|0} mo`, c: colors[7]})
    }
    else {
        let v = dt / 12 / 30 / 60 / 60 / 24;
        return ({v: `${v|0} yr`, c: colors[7]})
    }
}

export function check_and_parse_negative(v, default_neg="N/A") {
    if(v < 0) {
        return(default_neg);
    }
    return (v);
}

export function get_hostname_from_url(v) {
    let url = new URL(v)
    return(url.hostname)
}

export function isObjectEmpty(obj) {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
        break
    }
    return true;
}

export function compute_daily_emission(block_time, block_reward, daily_emission) {
    return(24 * 60 * 60 / block_time * block_reward * daily_emission)
}

const months3 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const weekday3 = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
export function date_to_dddd_MMMM_DD_YYYY_at_HH_mm(unix_time) {
    let dt = new Date(unix_time*1000)
    let year = dt.getFullYear()
    let month = months3[dt.getMonth()]
    let day_of_week = weekday[dt.getDay()]

    let day = dt.getDate()
    let hour = dt.getHours().toString().padStart(2, '0')
    let min = dt.getMinutes().toString().padStart(2, '0')

    return(`${day_of_week}, ${month} ${day}, ${year} at ${hour}:${min}`)
}

export function date_to_ddd_MMM_D_hh_mm(unix_time) {
    let dt = new Date(unix_time*1000)
    let day_of_week = weekday3[dt.getDay()]
    let month = months3[dt.getMonth()]
    let day = dt.getDate()
    let hour = dt.getHours().toString().padStart(2, '0')
    let min = dt.getMinutes().toString().padStart(2, '0')

    return(`${day_of_week}, ${month} ${day} ${hour}:${min}`)
}

export function date_to_MMM_D_YYYY_hh_mm(unix_time) {
    let dt = new Date(unix_time*1000)
    let month = months3[dt.getMonth()]
    let day = dt.getDate()
    let year = dt.getFullYear()
    let hour = dt.getHours().toString().padStart(2, '0')
    let min = dt.getMinutes().toString().padStart(2, '0')

    return(`${month} ${day}, ${year} ${hour}:${min}`)
}

export function ms_to_date_time(time_in_sec, default_neg="") {
    if(time_in_sec<0) {
        return (default_neg);
    }
    return (date_to_ddd_MMM_D_hh_mm(time_in_sec))
}

export function compute_profit(revenue, power, electric_price=0.1) {

    return revenue - (electric_price * power * 24 / 1000)
}

export function get_device_type_prefix(key_type) {
    if(key_type === "gpu_nvidia")
        return("GPU Nvidia")
    else if(key_type === "gpu_amd")
        return("GPU AMD")
    else if(key_type === "gpu_intel")
        return("GPU Intel")
    else if(key_type === "cpu_amd")
        return("CPU AMD")
    else if(key_type === "cpu_intel")
        return("CPU Intel")
    else
        return ("")
}

export function get_dict_oc(){
    return {
        "mc": "Memory Clock",
        "cc": "Core Clock",
        "hr": "Hashrate",
        "pw": "Power",
        "pl": "Power Limit",
        "uv": "Undervolt",
        "cco": "Core Clock Offset",
        "mco": "Memory Clock Offset",
        "mvdd": "MVDD",
        "vddci": "VDDCI",
        "dpm": "DPM",
        "param": "Parameter",
        "app": "Software",
        "eff": "Efficiency",
        "rw": "Rewards",
        "er": "Earnings",
        "pr": "Profit",
        "rv": "Revenue"
    }
}