import {Typography, useTheme} from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

function ViewItemPlus(props) {
    const theme = useTheme();
    const styles = {
        icon: {
            color: theme.palette.info.main,
            fontSize: '120%'
        }
    }
    return (
        <>
            <Typography onClick={props.onClick} style={{cursor: 'pointer'}} >
                <AddBoxOutlinedIcon style={styles.icon}/>
            </Typography>
        </>
    )
}

export default ViewItemPlus