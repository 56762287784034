export const chartSparkWidth = 110
export const chartSparkHeight = 37
export const barProgressWidth = 90
export const tableHeaderFontSize = '13px'
export const DATA_UPDATED_EVERY_MINUTES = 5
export const SPARK_TIME_SPACING = 7200  // spark chart spacing in second H * 60 * 60
export const MAX_DATA_VIEW = 1000     // maximum coin shown per view
export const MAX_COIN_PER_VIEW = 25     // maximum coin shown per view
export const MAX_POOL_PER_VIEW = 15     // maximum pool shown per view
export const MAX_MARKET_PER_VIEW = 15   // maximum market shown per view
export const MAX_DEVICE_OC_PER_VIEW = 15   // maximum market shown per view
export const USE_SINGLE_OC = true // use only the first oc when have multiple oc parameter
export const MAX_API_AS_ERROR = -48 // show api as error when counter less than this

export const TEST_ADS = false

// export const SERVER_URL = "http://localhost"
// export const ASSETS_URL = SERVER_URL
// export const ENABLE_MARKET_API = true
// export const USE_ADS = false
// export const DEBUG_MODE = true
export const SERVER_URL = "https://api.miningrank.com"
export const ASSETS_URL = "https://miningrank.com"
export const ENABLE_MARKET_API = true
export const USE_ADS = false
export const DEBUG_MODE = false