import {Typography, useTheme} from "@mui/material";

function ViewPoolFee(props) {
    const theme = useTheme();
    return (
        <>
            {
                props.value.map(item =>
                    <div key={item["mode"]}>
                        <Typography sx={{
                            fontSize: '85%', color: item["mode"] ===
                            'SOLO' ? theme.palette.error.light : theme.palette.info.main
                        }} display="inline">{item["mode"]}</Typography>
                        <Typography sx={{fontSize: '85%'}} display="inline"> {item["fee"]}</Typography>
                    </div>
                )
            }
        </>
    )
}

export default ViewPoolFee