import { useEffect, useRef } from 'react'

function AdsterraHeaderMobile(){
    const banner = useRef(null)

    const adsOptions = {
        'key' : '54ab37bbcec689bfd30c9b584e0412ae',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
    }

    useEffect(() => {
        if (banner.current && !banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.highperformancedformats.com/${adsOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(adsOptions)}`
            banner.current.append(conf)
            banner.current.append(script)
        }
        // eslint-disable-next-line
    }, [banner])

    return <div className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center" ref={banner}></div>
}
export default AdsterraHeaderMobile