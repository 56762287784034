import {tableHeaderFontSize} from "../config";

export const styles_table = {
    header: {
        fontWeight: 'bold',
    },
    subheader: {
        fontWeight: 'bold',
        fontSize: '80%',
        color: "text.secondary",
    },
    subheader_value: {
        fontWeight: 'bold',
        fontSize: '80%',
        color: "text.secondary",
        textAlign: 'left',
        paddingRight: '15px'
    },
    subheader_normal: {
        fontWeight: 'normal',
        fontSize: '80%',
        color: "text.secondary"
    },
    head_cell_prop: {
        fontWeight: 'bold',
        fontSize: tableHeaderFontSize,
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    body_cell_prop: {
        // paddingLeft: '3px',
        // paddingRight: '3px',
    },
    table_container_prop: {
        maxHeight: '100%'
    },
    body_cell_market_prop: {
        paddingLeft: '3px',
        paddingRight: '3px',
        paddingTop: '2px',
        paddingBottom: '2px',
    },
}

export const styles_card = {
    subtitle: {
        verticalAlign: 'top',
        width: '140px',
    },
    subvalue: {
        color: 'text.secondary'
    },
    market_pair: {
        color: 'text.secondary',
    },
    column_subtitle: {
        // paddingRight: '15px'
    },
}

export const styles_tab = {
    tab: {
        minHeight: '36px',
        height: '36px',
        fontWeight: 'bold'
        // textTransform: 'none'
    }
}

export const styles_typo = {
    card_title: {
        fontSize: "100%",
        fontWeight: 'bold'
    },
    card_title_secondary: {
        fontSize: "100%",
        fontWeight: 'normal',
        color: 'text.secondary',
    },
    col_desc: {
        fontSize: "70%",
        fontWeight: 'normal',
        color: 'text.secondary',
    },
}

export const styles_table_input = {
    desc: {
        verticalAlign: 'top',
        width: '130px',
        textAlign: 'right'
    },
    value: {
        color: 'text.secondary'
    }
}