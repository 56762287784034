
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {getCoinLogo} from "../../router/router-path";

function ColumnCoinHeader(props) {
    const theme = useTheme();
    const styles = {
        grid: {
            alignItems: "center",
            alignContent: 'center',
            pl: 1
        },
        coin: {
            fontWeight: 'bold',
            fontSize: '100%'
        },
        algo: {
            fontWeight: 'normal',
            fontSize: '85%',
            color: theme.palette.info.main
        },
        unit: {
            color: theme.palette.info.main
        }
    }
    const create_col_coin_logo = (v) => {
        if (v === undefined)
            return (<></>)

        if (v === "")
            return (<></>)

        else
            return(
                <>
                    <Box
                        component="img"
                        sx={{height: 24, width: 24, pr: "3px"}}
                        src={getCoinLogo(v)}
                    />
                </>
            )
    }

    return (
        <>
            <Grid container sx={styles.grid}>
                {create_col_coin_logo(props.data["coinKey"])}
                <Box>
                    <Typography sx={styles.coin}>{props.data["coinName"]} - <span style={styles.unit}>{props.data["coinSymbol"]}</span></Typography>
                    <Typography sx={styles.algo}>{props.data["algoName"]}</Typography>
                </Box>
            </Grid>
        </>
    )
}

export default ColumnCoinHeader