import {Box, Link, Stack, Typography} from "@mui/material";
import Marquee from "react-fast-marquee";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {useTheme} from "@mui/material/styles";
import CoinLogoAlgoView from "../item/coin-logo-algo-view";

const RunningText = ({data, animSpeed, logoSize}) => {
    const theme = useTheme()
    const styles = {
        stack: {
            alignItems: "center",
        },
        stackPositive: {
            alignItems: "center",
            color: theme.palette.success.main,
            pr: 1
        },
        stackNegative: {
            alignItems: "center",
            color: theme.palette.error.main,
            pr: 1
        },
        textDesc: {
            // fontSize: '100%'
        },
        coinPrice: {
            pl: 1,
            color: theme.palette.text.primary
        }
    }
    const createItem = (item) => {
        return (
            <>
                <Stack direction={'row'} sx={styles.stack}>
                    <CoinLogoAlgoView logoSize={logoSize} coinKey={item.key} name={item.symbol}
                                      showSymbol={false} showAlgo={false}/>
                    <Typography sx={styles.coinPrice}>${item["price"]}</Typography>
                    {
                        item["prPrice1D"] < 0 ?
                            <Stack direction={'row'} sx={styles.stackNegative}>
                                <ArrowDropDownIcon/>
                                <Typography sx={styles.textDesc}>{item["prPrice1D"]}%</Typography>
                            </Stack>
                            :
                            <Stack direction={'row'} sx={styles.stackPositive}>
                                <ArrowDropUpIcon/>
                                <Typography sx={styles.textDesc}>{item["prPrice1D"]}%</Typography>
                            </Stack>
                    }
                </Stack>
            </>
        )
    }

    return (
        <>
            <Marquee speed={animSpeed ? animSpeed : 30} gradient={false}>
                {data.map((item, idx) => (
                    <Box sx={{mr: 2}} key={idx}>{createItem(item)}</Box>
                ))}
            </Marquee>
        </>
    )
}
export default RunningText